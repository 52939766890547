import { Suspense, useEffect, useRef, useState } from 'react'
import { extend } from 'lodash'

import { LoadingPanel, useNotify } from '@cutover/react-ui'
import { RunbookEdit, RunbookSubmitData } from 'main/components/shared/runbook-edit/runbook-edit'
import { useRightPanelTypeState } from 'main/components/layout/right-panel'
import { useRunbookEdit } from 'main/services/queries/use-runbook-edit'
import { usePermissions } from 'main/services/queries/use-permissions'
import {
  useAccount,
  useAccountCustomFieldGroups,
  useAccountCustomFields,
  useAccountCustomFieldUsers,
  useAccountRunbookTypes
} from 'main/services/api/data-providers/account/account-data'
import { usePermittedResources } from 'main/services/queries/use-permitted-resources'
import { useRunbookTypeById } from 'main/components/shared/hooks/runbook'
import { useUpdateRunbook } from 'main/services/queries/use-update-runbook'
import { useLanguage, useWebsockets } from 'main/services/hooks'
import { RunbookEditRunbook, StreamListStream, TaskListTask, TaskType } from 'main/services/queries/types'
import { useAccountSlugUrlParamState, useTaskSelectDataCallback } from 'main/recoil/data-access'
import { SharedViewModel } from 'main/data-access/view-models/shared-view-model'
import { RunbookRefreshCustomFieldResponse } from 'main/services/api/data-providers/runbook-types'

export const RunbookEditPanel = () => {
  const [{ runbookId }] = useRightPanelTypeState('runbook-edit')

  return (
    <Suspense fallback={<LoadingPanel />}>
      <RunbookEditPanelInner key={runbookId} />
    </Suspense>
  )
}

const RunbookEditPanelInner = () => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | string[] | null>(null)
  const notify = useNotify()
  const { t } = useLanguage('runbook', { keyPrefix: 'editPanel' })

  const setCustomFieldRefresh = SharedViewModel.useAction('customFieldRefresh:update')
  const { mutateAsync: updateRunbook } = useUpdateRunbook()
  const [{ runbookId, type }] = useRightPanelTypeState('runbook-edit')
  const isRunbookEditPanel = type === 'runbook-edit'
  const { runbookTypes } = useAccountRunbookTypes()
  const { account } = useAccount()
  const permissions = usePermissions('runbook-edit')
  const canUpdate = permissions('update')
  const { customFieldsLookup } = useAccountCustomFields()
  const { customFieldUsers } = useAccountCustomFieldUsers()
  const { customFieldGroupsLookup, customFieldGroups } = useAccountCustomFieldGroups()
  const accountSlug = useAccountSlugUrlParamState()
  const { data: runbookData } = useRunbookEdit(runbookId, accountSlug)
  const runbook = runbookData?.runbook
  const [editingRunbook, setEditingRunbook] = useState<RunbookEditRunbook | undefined>(undefined)
  const runbookVersionId = runbook?.current_version.id
  const taskSelectData = useTaskSelectDataCallback()

  const websocketRef = useRef<ActionCable.Channel | undefined>()
  const { subscribe, findExistingSubscription } = useWebsockets()
  const [connected, setConnected] = useState(!!findExistingSubscription('RunbookChannel', runbookId))

  const { data: permittedProjectsData, isLoading: isLoadingPermittedProjects } = usePermittedResources({
    resource: 'runbook',
    accountId: account?.id
  })
  const runbookType = useRunbookTypeById(runbook?.runbook_type_id)

  const customFieldProps = {
    customFieldsLookup,
    customFieldUsers,
    customFieldGroupsLookup,
    customFieldGroups
  }

  const [taskLookup, setTaskLookup] = useState<Record<string, TaskListTask>>({})
  const [taskTypeLookup, setTaskTypeLookup] = useState<Record<string, TaskType>>({})
  const [streamLookup, setStreamLookup] = useState<Record<string, StreamListStream>>({})

  useEffect(() => {
    getData()
    setEditingRunbook(runbook)
  }, [runbook, accountSlug])

  const getData = async () => {
    if (runbookType?.enable_rto && runbook && accountSlug && (runbook.is_template || runbook.template_type === 'off')) {
      const { taskTypeLookup, streamLookup, taskLookup } = await taskSelectData({
        accountSlug,
        runbookId: runbook?.id,
        runbookVersionId
      })
      setTaskLookup(taskLookup)
      setTaskTypeLookup(taskTypeLookup)
      setStreamLookup(streamLookup)
    }
  }

  const handleSubmit = async ({ data, payload, timezone }: RunbookSubmitData) => {
    setSubmitting(true)
    updateRunbook(
      {
        ...data,
        runbook: payload,
        timezone
      },
      {
        onSuccess: () => {
          notify.success(t('updateSuccess'))
          setSubmitting(false)
        },
        onError: response => {
          if (response.errors) {
            setErrorMessage(response.errors)
          }
          notify.error(t('updateError'))
          setSubmitting(false)
        }
      }
    )
  }

  // Listen on the runbook channel for searchable custom field updates
  useEffect(() => {
    if (!runbookId) return

    const existingRunbookChannel = findExistingSubscription('RunbookChannel', runbookId)
    if (existingRunbookChannel) return setConnected(true)

    websocketRef.current?.unsubscribe?.()

    websocketRef.current = subscribe('RunbookChannel', runbookId, {
      connected: () => setConnected(true),
      disconnected: () => setConnected(false),
      received: (response: RunbookRefreshCustomFieldResponse) => {
        const { headers } = response.meta
        if (headers.request_method === 'refresh_custom_field' && headers.request_class === 'Runbook') {
          setEditingRunbook(extend(editingRunbook, response.runbook))

          const fieldValue = response.runbook.field_values.find(fv => fv.id === headers.searchable_field_id)
          const fieldValueId = fieldValue?.id
          const customFieldId = fieldValue?.custom_field_id
          if (fieldValueId && customFieldId) {
            setCustomFieldRefresh({
              customFieldId,
              refreshState: {
                refresh: false,
                errors: { [fieldValueId]: headers.errors }
              }
            })
          }
        }
      }
    })

    return () => websocketRef.current?.unsubscribe?.()
  }, [runbookId])

  return connected &&
    isRunbookEditPanel &&
    editingRunbook &&
    permittedProjectsData?.projects &&
    runbookType &&
    runbookTypes ? (
    <RunbookEdit
      runbook={editingRunbook}
      taskLookup={taskLookup}
      taskTypeLookup={taskTypeLookup}
      streamLookup={streamLookup}
      runbookType={runbookType}
      runbookTypes={runbookTypes}
      permittedProjectsData={permittedProjectsData}
      isLoading={isLoadingPermittedProjects}
      isRunbookPage={false}
      readOnly={!canUpdate}
      customFieldProps={customFieldProps}
      account={account}
      isSubmitting={isSubmitting}
      apiErrorMessage={errorMessage}
      onSubmit={handleSubmit}
    />
  ) : (
    <LoadingPanel />
  )
}
