import { useState } from 'react'

import { RunbookActivityPage } from '../components/activities/runbook-activity-page'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'

export const ActivitiesPageConnector = () => {
  const [runbookId, setRunbookId] = useState<number | null>(null)
  const [currentUserId, setCurrentUserId] = useState<number | null>(null)
  const [timezone, setTimezone] = useState('')
  const [readOnlyActivityComment, setReadOnlyActivityComment] = useState<boolean>(false)

  return (
    <ReactAngularConnector
      mountEvent="show-runbook-activities"
      unmountEvent="hide-runbook-activities"
      onMount={({ runbookId, currentUserId, timezone, readOnlyActivityComment }) => {
        setRunbookId(runbookId)
        setCurrentUserId(currentUserId)
        setTimezone(timezone)
        setReadOnlyActivityComment(readOnlyActivityComment)
      }}
    >
      {runbookId && currentUserId && (
        <RunbookActivityPage
          runbookId={runbookId}
          currentUserId={currentUserId}
          timezone={timezone}
          readOnlyActivityComment={readOnlyActivityComment}
        />
      )}
    </ReactAngularConnector>
  )
}
