import { createContext, ReactNode, useState } from 'react'
import { useMount, useUnmount } from 'react-use'

import { UserChannelResponse } from '../data-providers/user/user-channel-response-types'
import { useWebsockets } from 'main/services/hooks'
import { CurrentUserModel } from 'main/data-access'

const USER_CHANNEL_NAME = 'UserChannel'

export const UserChannelContext = createContext<UserChannelResponse | undefined>(undefined)

export const UserChannelProvider = ({ children }: { children: ReactNode }) => {
  const websockets = useWebsockets()
  const user = CurrentUserModel.useGet()
  const subscriptionId = user.id

  const [userChannelResponse, setUserChannelResponse] = useState<UserChannelResponse>()

  const userChannelSubscription = websockets.findExistingSubscription(USER_CHANNEL_NAME, subscriptionId)

  // TODO: Have more consistent WS responses
  const getRequestHash = (data: UserChannelResponse) => {
    if (data && 'meta' in data && data.meta && data.meta.headers && 'request_hash' in data.meta.headers) {
      return data.meta.headers.request_hash
    }
  }

  useMount(() => {
    setUserChannelResponse({} as UserChannelResponse)
    if (!userChannelSubscription) {
      websockets.subscribe(USER_CHANNEL_NAME, subscriptionId, {
        received: (data: UserChannelResponse) => {
          const requestHash = getRequestHash(data)
          const browserHash = window.sessionStorage.getItem('browserHash') as string

          // Ignore messages not on active session
          if (requestHash && browserHash && requestHash !== browserHash) {
            return
          }

          setUserChannelResponse(data)
        }
      })
    }
  })

  useUnmount(() => {
    userChannelSubscription?.unsubscribe()
  })

  return <UserChannelContext.Provider value={userChannelResponse}>{children}</UserChannelContext.Provider>
}
