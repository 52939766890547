import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'

import { useGetRunbook } from 'main/services/queries/use-runbook'
import { useGetRunbookVersion } from 'main/services/queries/use-runbook-versions'
import { useGetTasks } from 'main/services/queries/use-tasks'
import { GlobalStateSetupModel } from 'main/data-access'
import { useAccountDataState } from 'main/services/api/data-providers/account/account-data'

// NOTE: cache time is set to 0 bc we always want most up to date info whenever we enter a runbook
const CACHE_TIME = 0

export const RunbookPageRequests = ({
  children
}: {
  children: (context: { isLoading: boolean; isError: boolean }) => ReactNode
}) => {
  const { runbookId, runbookVersionId } = useParams<{
    runbookId: string
    runbookVersionId: string
  }>()
  const setRunbookRequestData = GlobalStateSetupModel.useSet('runbook')
  const setRunbookVersionRequestData = GlobalStateSetupModel.useSet('runbookVersion')
  const setTaskListRequestData = GlobalStateSetupModel.useSet('tasks')

  const { isFetching: accountDataIsLoading, isError: accountDataError } = useAccountDataState()
  const { isLoading: runbookDataLoading, isError: runbookDataError } = useGetRunbook(
    { runbookId },
    {
      onSuccess: setRunbookRequestData,
      cacheTime: CACHE_TIME
    }
  )

  const {
    data: runbookVersionData,
    isLoading: runbookVersionDataLoading,
    isError: runbookVersionDataError
  } = useGetRunbookVersion(
    { runbookId, runbookVersionId },
    {
      onSuccess: setRunbookVersionRequestData,
      cacheTime: CACHE_TIME
    }
  )

  const { isLoading: taskListDataLoading, isError: taskListDataError } = useGetTasks(
    { runbookId, runbookVersionId },
    // NOTE: make task list request when runbook version request has finished bc we need the runbook version response
    // to populate initial lookups (eg, task id to runbook component field value lookup)
    {
      enabled:
        !!runbookVersionData &&
        !runbookVersionDataLoading &&
        !runbookVersionDataError &&
        !!runbookId &&
        !!runbookVersionId,
      onSuccess: setTaskListRequestData,
      cacheTime: CACHE_TIME
    }
  )

  const isLoading = runbookDataLoading || runbookVersionDataLoading || taskListDataLoading || accountDataIsLoading
  const isError = runbookVersionDataError || taskListDataError || runbookDataError || accountDataError

  return (
    <>
      {children({
        // NOTE: we don't return data here because it's set in recoil state. If you need access to it, please
        // use the relevant models (eg. TaskModel, ActiveRunbookModel)
        isLoading,
        isError
      })}
    </>
  )
}
