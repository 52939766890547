import { Dispatch, SetStateAction, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { AccordionPanel, Box, Icon, ListItem, Message, Pill, Text } from '@cutover/react-ui'
import { ConfigModel } from 'main/data-access'
import { RunbookEditFormType } from './runbook-edit-form'
import { useLanguage } from 'main/services/hooks'
import {
  Account,
  CustomField,
  RunbookEditRunbook,
  StreamListStream,
  TaskListTask,
  TaskType
} from 'main/services/queries/types'
import { DurationPickerField, RadioboxGroupField, SelectField } from '../../form'
import { TaskSelectField } from '../../form/task-select'
import { useRouting } from 'main/services/routing/hooks'

type RunbookEditRtoSettingsPanelProps = {
  runbook: RunbookEditRunbook
  account: Account
  taskLookup: Record<string, TaskListTask>
  taskTypeLookup: Record<string, TaskType>
  streamLookup: Record<string, StreamListStream>
  customFields: CustomField[]
  disabled?: boolean
  readOnly?: boolean
  rtoSource: string
  setRtoSource: Dispatch<SetStateAction<string>>
}

export const RunbookEditRtoPanel = ({
  runbook,
  account,
  taskLookup,
  taskTypeLookup,
  streamLookup,
  disabled,
  readOnly,
  customFields,
  rtoSource,
  setRtoSource
}: RunbookEditRtoSettingsPanelProps) => {
  const { t } = useLanguage('runbooks', { keyPrefix: 'fields' })
  const rtaResults = runbook.rta_results
    ? Array.isArray(runbook.rta_results)
      ? runbook.rta_results
      : [runbook.rta_results]
    : []
  const runbookOrTemplate = runbook.template_type === 'off' ? 'runbook' : 'template'
  const { setValue, getValues } = useFormContext<RunbookEditFormType>()
  const { toRunbook } = useRouting()
  const { watch } = useFormContext()
  const rtoStartTaskId = watch('runbook.runbook_versions_attributes.0.rto_start_task_id')
  const rtoEndTaskId = watch('runbook.runbook_versions_attributes.0.rto_end_task_id')
  const rtoSourceSelection = watch('toggle_rto_source')
  const { rtoStatuses } = ConfigModel.useGet()

  const ids = Object.keys(taskLookup)
  const possibleTasks = ids.map(id => taskLookup[id])
  const navigate = useNavigate()

  useEffect(() => {
    if (rtoSourceSelection === 'choose_field') {
      setValue('runbook.rto', null)
    } else {
      setValue('runbook.rto_source_id', null)
    }

    setRtoSource(rtoSourceSelection)
  }, [rtoSourceSelection])

  const RtaResultsPanel = () => {
    let rows = rtaResults.map(result => {
      let status = rtoStatusForStatusResultName(result.rto_status)
      let date = new Date(result.start_actual)
      let formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })

      const Separator = () => {
        return (
          <Text color={'text-light'} size="small">
            {' '}
            •{' '}
          </Text>
        )
      }

      return (
        <ListItem
          title={formattedDate.toString()}
          size="small"
          key={result.runbook_version_id}
          onClick={() =>
            result.runbook_id !== runbook.id
              ? navigate(
                  toRunbook({
                    accountSlug: account.slug,
                    runbookId: result.runbook_id,
                    versionId: result.runbook_version_id
                  })
                )
              : undefined
          }
          startComponent={<Icon icon="runbook" size="small" />}
          endComponents={[
            <Text color="text-light" size="small">
              ID #{result.runbook_id}
            </Text>,
            <Text color="text-light" size="small">
              (v{result.runbook_version_id})
            </Text>,
            <Separator />,
            <Text color="text-light" size="small">
              {result.rta_duration}
            </Text>,
            <Separator />,
            <Text color={status?.color} size="small">
              {result.rta_diff_duration}
            </Text>,
            <Separator />,
            <Pill label={status?.name} color={status?.color} size="small" />
          ]}
        />
      )
    })

    // TODO: move to i18n
    return (
      <Box>
        <Text size="small" color="text-light">
          Runbook RTA results
        </Text>
        {rows}
      </Box>
    )
  }

  return (
    <AccordionPanel
      label={t('rtoRta.label')}
      icon="target"
      iconColor={runbook.project.color}
      labelSuffix={getVersionStatusPill()}
    >
      <RadioboxGroupField<RunbookEditFormType>
        name="toggle_rto_source"
        direction="row"
        label={t('rtoRta.chooseSource')}
        disabled={disabled}
        readOnly={readOnly}
        options={[
          { value: 'define_manually', label: t('rtoRta.manualSource') },
          { value: 'choose_field', label: t('rtoRta.fieldSource') }
        ]}
        defaultValue={rtoSource}
      />

      {rtoSourceSelection == 'define_manually' && (
        <DurationPickerField<RunbookEditFormType>
          label={t('rtoRta.rto.label')}
          disabled={disabled}
          readOnly={readOnly}
          value={runbook.rto || undefined}
          name="runbook.rto"
        />
      )}

      {rtoSourceSelection == 'choose_field' && (
        <SelectField
          required={rtoIsRequired()}
          disabled={disabled}
          readOnly={readOnly}
          name="runbook.rto_source_id"
          label={t('rtoRta.rtoSource.label')}
          options={
            customFields ? customFields.map(customField => ({ label: customField.name, value: customField.id })) : []
          }
        />
      )}

      <TaskSelectField<RunbookEditFormType>
        key={'runbook.runbook_versions_attributes.0.rto_start_task_id'}
        label={t('rtoRta.startTask.label')}
        name="runbook.runbook_versions_attributes.0.rto_start_task_id"
        tasks={readOnly ? [taskLookup[rtoStartTaskId]] : possibleTasks}
        taskTypeLookup={taskTypeLookup}
        streamLookup={streamLookup}
        single
        required={rtoIsRequired()}
        disabled={disabled}
        readOnly={readOnly}
        disableTaskClick
      />

      <TaskSelectField<RunbookEditFormType>
        key={'runbook.runbook_versions_attributes.0.rto_end_task_id'}
        label={t('rtoRta.endTask.label')}
        name="runbook.runbook_versions_attributes.0.rto_end_task_id"
        tasks={readOnly ? [taskLookup[rtoEndTaskId]] : possibleTasks}
        taskTypeLookup={taskTypeLookup}
        streamLookup={streamLookup}
        single
        required={rtoIsRequired()}
        disabled={disabled}
        readOnly={readOnly}
        disableTaskClick
      />

      {runbook.current_version.rto_status === 'pending' && (
        <Message type="info">{t('rtoRta.rtaResultsInfo', { runbookOrTemplate: runbookOrTemplate })}</Message>
      )}

      {!runbook.current_version.rto_start_task_id && !runbook.is_template && !runbook.parent_runbook_id && (
        <Message type="info">{t('rtoRta.mustBeTemplate')}</Message>
      )}

      {rtaResults.length > 0 && <RtaResultsPanel />}
    </AccordionPanel>
  )

  function rtoStatusForStatusResultName(rto_status_name: string) {
    return rtoStatuses.filter(obj => obj.id === rto_status_name)[0]
  }

  function getVersionStatusPill() {
    if (runbook.current_version.rto_status) {
      return (
        <Pill
          css="margin-left: 4px; flex-shrink: 0;"
          label={runbook.current_version.rto_status!}
          color={rtoStatusForStatusResultName(runbook.current_version.rto_status!).color}
          size="small"
        />
      )
    } else if (runbook.is_template && runbook.rta_results?.length > 0) {
      return (
        <Pill
          css="margin-left: 4px; flex-shrink: 0;"
          label={runbook.rta_results[0].rto_status}
          color={rtoStatusForStatusResultName(runbook.rta_results[0].rto_status!).color}
          size="small"
        />
      )
    } else if (!runbook.current_version.rto_start_task_id) {
      return (
        <Pill
          css="margin-left: 4px; flex-shrink: 0;"
          label={t('rtoRta.notConfigured')}
          color={'rgb(216,221,223)'}
          size="small"
        />
      )
    }
  }

  function rtoIsRequired() {
    return (
      (getValues([
        'runbook.rto_source_id',
        'runbook.runbook_versions_attributes.0.rto_start_task_id',
        'runbook.runbook_versions_attributes.0.rto_end_task_id'
      ]).filter(el => el !== null).length > 0 &&
        getValues('runbook.rto') !== null) ||
      getValues('runbook.rto_source_id') !== 0
    )
  }
}
