import { parseISO } from 'date-fns'
import { format as formatDate, utcToZonedTime } from 'date-fns-tz'

import { ActivityConfig, ActivityVM, Trackable } from 'main/services/queries/use-activities'
import { OptionSection } from '../feed/filter/activity-filter-panel'

export type ActivitySubjectProperties = { text: string; internalIds?: number[] }

export const useActivityProperties = (activity: ActivityVM, activityConfig: ActivityConfig[]) => {
  return {
    activityIcon: renderActivityIcon(activity.key, activityConfig),
    subject: renderActivitySubject(activity),
    action: renderActivityAction(activity),
    activistName: renderActivistName(activity)
  }
}

const getSubjectInternalIds = (trackables: Trackable[], trackableType: string) => {
  // Can add more conditions for other trackable types if we want to use their internal_ids
  if (trackableType !== 'Task') return undefined
  return trackables?.reduce((ids: number[], trackable: Trackable) => {
    if (trackable.properties && trackable.properties.internal_id) ids.push(trackable.properties.internal_id)
    return ids
  }, [])
}

const getSubjectText = (trackables: Trackable[], trackableType: string) => {
  if (trackables?.length > 1) {
    return `${trackables?.length} ${trackableType.toLowerCase()}s`
    // Can add more conditions for other trackable types if we want different text
  } else if (trackableType === 'Task') {
    return `${trackables[0].properties?.internal_id ? '#' + trackables[0].properties?.internal_id : ''} ${
      trackables[0].properties?.name
    }`
  } else {
    return trackables?.[0].properties?.name || ''
  }
}

export const renderActivitySubject = (activity: ActivityVM): ActivitySubjectProperties => {
  const emptySubject = { text: '' }

  if (activityHasEmptySubject(activity.key, activity)) return emptySubject

  const trackableType = activity.trackables[0]?.type
  if (!activitySubject.includes(trackableType)) return { text: 'Unknown subject' }

  const trackables = activity.trackables?.filter(t => t.type === trackableType)
  return {
    text: getSubjectText(trackables, trackableType),
    internalIds: getSubjectInternalIds(trackables, trackableType)
  }
}

const activityHasEmptySubject = (key: string, activity: ActivityVM): boolean => {
  return (
    key === 'runbook.commented' ||
    key === 'run.finished' ||
    key.includes('external') ||
    key === 'runbook.created' ||
    activity.trackables.length === 0
  )
}

const renderActivityIcon = (key: string, activityConfig: ActivityConfig[]) => {
  const activityAction = activityConfig.filter(activityInfo => {
    return activityInfo.key === key
  })
  const activityActionIcon = activityAction[0] && activityAction[0].icon
  return activityActionIcon
}

const renderActivityAction = (activity: ActivityVM) => {
  if ((activity?.trackables ?? []).length > 1) {
    return activity.text
  }

  const trackableType = ['Runbook', 'Task', 'Stream'].find(type => {
    return activity.trackables?.find(obj => obj.type === type)
  })

  const activityAction = trackableType ? `${activity.text} ${trackableType.toLowerCase()}` : activity.text
  return activityAction
}

export const renderActivistName = (activity: ActivityVM) => {
  return activity.activist.type === 'Runbook' ? 'Cutover' : activity.activist.properties.name
}

const activitySubject = ['RunbookTeam', 'User', 'Runbook', 'Task', 'Stream']

export const formatActivityDetailsValue = (value: string, timezone: string, longFormatFlag: boolean = false) => {
  const regex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)/g
  const longFormat = 'eee, dd MMM yyyy HH:mm'
  const shortFormat = 'yyyy-MM-dd HH:mm:ss'
  const formattedString = value.replace(regex, match => {
    const date = parseISO(match)
    return formatDate(utcToZonedTime(new Date(date), timezone), longFormatFlag ? longFormat : shortFormat)
  })

  return formattedString
}

/*
 *  Filter options helpers
 */
export const getGroupedFilterOptions = (configs: ActivityConfig[]) => {
  // for each config, we want to group by the group attr
  // for each group, we want to build the items based on the text and key
  return configs.reduce<{ [key: string]: OptionSection }>((options, currentConfig) => {
    const { group, key } = currentConfig
    const sectionTitle = getFilterOptionSectionTitleFromConfig(currentConfig)
    const itemTitle = getFilterOptionItemTitleFromConfig(currentConfig)

    if (!options[group]) {
      options[group] = {
        key: group,
        title: sectionTitle,
        items: []
      }
    }

    if (!excludedActionFilterOptions(key))
      options[group].items.push({
        title: itemTitle,
        key: key.includes('external') || !FILTER_KEY_MAP.hasOwnProperty(key) ? [key] : FILTER_KEY_MAP[key]
      })

    return options
  }, {})
}

const getFilterOptionItemTitleFromConfig = (config: ActivityConfig) => {
  const { key, text } = config
  let subject = key.split('.')[0]
  subject = subject === 'run' ? 'task' : subject
  return subject === 'external' ? text : key.split('.')[1].charAt(0).toUpperCase() + key.split('.')[1].slice(1)
}

const getFilterOptionSectionTitleFromConfig = (config: ActivityConfig) => {
  const { group, key } = config
  let subject = key.split('.')[0]
  subject = subject === 'run' ? 'task' : subject
  return subject === 'external' ? group : `${group.charAt(0).toUpperCase() + group.slice(1)} Actions`
}

export const excludedActionFilterOptions = (key: string) => {
  return key === 'run.finished' || key === 'runbook.commented' || key === 'featured' || key === 'run.created'
}

const FILTER_KEY_MAP: { [key: string]: string[] } = {
  'task.abandoned': ['task.abandoned'],
  'task.created': ['task.created'],
  'task.commented': ['task.commented'],
  'task.deleted': ['task.deleted'],
  'task.finished': ['task.finished', 'run.finished'],
  'task.skipped': ['task.skipped'],
  'task.started': ['task.started'],
  'task.updated': ['task.updated'],
  'runbook.created': ['runbook.created'],
  'runbook.updated': ['runbook.updated'],
  'runbook.skipped': ['runbook.skipped'],
  'runbook.merged': ['runbook.merged'],
  'runbook.commented': ['runbook.commented'],
  'runbook.cancelled': ['runbook.cancelled'],
  'stream.created': ['stream.created'],
  'stream.updated': ['stream.updated'],
  'run.paused': ['run.paused'],
  'run.resumed': ['run.resumed']
}

/*
 * Activity Feed Input Helpers
 */
export const isWhitespaceOnly = (string: string) => {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = string?.trim()
  return tempDiv.textContent?.trim().length === 0
}

export function isOnlyHTMLTags(str: string) {
  const htmlOnlyRegex = /^(\s*<\/?[a-zA-Z][^>]*?>\s*)*$/
  return htmlOnlyRegex.test(str)
}
