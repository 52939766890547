import { ReactNode, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { ActiveRunbookModel, ConfigModel } from 'main/data-access'
import { useLanguage } from 'main/services/hooks'

export const RunbookRoutePage = ({ element, title }: { element: ReactNode; title: string }) => {
  const isReactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')

  return isReactRunbookEnabled ? <RunbookRoutePageInner element={element} title={title} /> : <Outlet />
}

const RunbookRoutePageInner = ({ element, title }: { element: ReactNode; title: string }) => {
  const { t } = useLanguage('common')

  const { name } = ActiveRunbookModel.useGet()
  const pageTitle = `${title} - ${t('cutover')}`

  useEffect(() => {
    document.title = !!name ? `${name} - ${pageTitle}` : `${pageTitle}`
  }, [element, name])

  return <>{element}</>
}
