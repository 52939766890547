import { useCallback } from 'react'
import {
  TransactionInterface_UNSTABLE,
  useRecoilCallback,
  useRecoilTransaction_UNSTABLE,
  useRecoilValue,
  useRecoilValueLoadable
} from 'recoil'
import { produce } from 'immer'
import { extend } from 'lodash'

import { useNotify } from '@cutover/react-ui'
import {
  getRunbookVersion,
  getRunbookVersion as getRunbookVersionRequest,
  GetRunbookVersionResponse
} from 'main/services/queries/use-runbook-versions'
import {
  runbookIdState,
  runbookResponseState_INTERNAL,
  runbookState,
  runbookVersionIdState,
  runbookVersionPermission,
  runbookVersionResponseState_INTERNAL,
  runbookVersionState,
  taskListResponseState_INTERNAL
} from 'main/recoil/runbook'
import {
  RunbookVersionConvertToTemplateResponse,
  RunbookVersionCreateResponse,
  RunbookVersionImportResponse,
  RunbookVersionMarkAsApprovedResponse,
  RunbookVersionMergedResponse,
  RunbookVersionRequestApprovalResponse,
  RunbookVersionSubmitReviewResponse
} from 'main/services/api/data-providers/runbook-types'
import { updateCurrentVersion } from './shared-updates'
import { Run, RunbookLinkedResourceDetails, RunbookTemplateType } from 'main/services/queries/types'
import { useNavigateUpdateRunbookVersion } from 'main/services/routing'
import { ActiveRunbookVersionModelType } from 'main/data-access/models'
import { useReloadTasks } from './task'
import { useEnsureStableArgs } from 'main/data-access/models/model-utils'
import { useLanguage } from 'main/services/hooks'
import { getTasks, TaskListResponseType } from 'main/services/queries/use-tasks'
import { taskListResponseDataHelpers } from './task-list-response-data-helpers'

const handleUnknownKey = (type: string, fnName: string): never => {
  throw new Error(`Unknown argument passed to ${fnName}: ${type}`)
}

/* -------------------------------------------------------------------------- */
/*                                     Get                                    */
/* -------------------------------------------------------------------------- */

export const useGetRunbookVersion: ActiveRunbookVersionModelType['useGet'] = () => {
  return useRecoilValue(runbookVersionState)
}

export const useGetRunbookVersionCallback: ActiveRunbookVersionModelType['useGetCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        await snapshot.getPromise(runbookVersionState),
    []
  )

/* -------------------------------------------------------------------------- */
/*                                     Id                                     */
/* -------------------------------------------------------------------------- */

export const useGetRunbookVersionId: ActiveRunbookVersionModelType['useId'] = () => {
  return useRecoilValue(runbookVersionState).id
}

export const useGetRunbookVersionIdCallback: ActiveRunbookVersionModelType['useIdCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      async () =>
        (await snapshot.getPromise(runbookVersionState)).id,
    []
  )

/* -------------------------------------------------------------------------- */
/*                                  Loadable                                  */
/* -------------------------------------------------------------------------- */

export const useGetRunbookVersionLoadable: ActiveRunbookVersionModelType['useGetLoadable'] = () =>
  useRecoilValueLoadable(runbookVersionState)

export const useGetRunbookVersionLoadableCallback: ActiveRunbookVersionModelType['useGetLoadableCallback'] = () =>
  useRecoilCallback(
    ({ snapshot }) =>
      () =>
        snapshot.getLoadable(runbookVersionState),
    []
  )

/* -------------------------------------------------------------------------- */
/*                                     Can                                    */
/* -------------------------------------------------------------------------- */
export const useCanRunbookVersion: ActiveRunbookVersionModelType['useCan'] = key => {
  return useRecoilValue(runbookVersionPermission({ attribute: key }))
}

/* -------------------------------------------------------------------------- */
/*                                   Reload                                   */
/* -------------------------------------------------------------------------- */

export const useReloadRunbookVersion: ActiveRunbookVersionModelType['useReload'] = () =>
  useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const rbId = await snapshot.getPromise(runbookIdState)
        const rbvId = await snapshot.getPromise(runbookVersionIdState)

        set(runbookVersionResponseState_INTERNAL, await getRunbookVersionRequest(rbId, rbvId))
      },
    []
  )

export const useReloadRunbookVersionSync: ActiveRunbookVersionModelType['useReloadSync'] = () => () => {
  window.dispatchEvent(new CustomEvent<any>('refresh-data-store', { detail: { type: 'runbook-version' } }))
}

/* -------------------------------------------------------------------------- */
/*                                   Action                                   */
/* -------------------------------------------------------------------------- */

const RUNBOOK_VERSION_ERRORS = {
  NOT_PLANNING_MODE: 'NOT_PLANNING_MODE',
  ALREADY_TEMPLATE: 'ALREADY_TEMPLATE',
  NO_PERMISSION: 'NO_PERMISSION',
  LINKED_RUNBOOK_CHILD: 'LINKED_RUNBOOK_CHILD'
}

const canConvertToTemplate = (
  templateType: RunbookTemplateType,
  run: Run | null,
  linkedRunbookDetails?: Partial<RunbookLinkedResourceDetails>
) => {
  const isPlanning = !['rehearsal', 'live'].includes(run?.run_type || '')
  const isLinkedRunbookChild = linkedRunbookDetails && !!linkedRunbookDetails.id
  if (isLinkedRunbookChild) return { can: false, error: RUNBOOK_VERSION_ERRORS.LINKED_RUNBOOK_CHILD }
  if (!isPlanning) return { can: false, error: RUNBOOK_VERSION_ERRORS.NOT_PLANNING_MODE }
  if (templateType !== 'off') return { can: false, error: RUNBOOK_VERSION_ERRORS.ALREADY_TEMPLATE }
  return { can: true, error: undefined }
}

export const useCanActionRunbookVersion: ActiveRunbookVersionModelType['usePermission'] = action => {
  useEnsureStableArgs(action)

  /* eslint-disable react-hooks/rules-of-hooks */
  switch (action) {
    case 'convert_to_template':
      const { template_type, linked_runbook_details } = useRecoilValue(runbookState)
      const { run } = useGetRunbookVersion()

      return canConvertToTemplate(template_type, run, linked_runbook_details)
    case 'runbook_version_import':
      const canImport = useCanRunbookVersion('import')
      return { can: canImport, error: canImport ? undefined : RUNBOOK_VERSION_ERRORS.NO_PERMISSION }
    case 'create':
      // TODO: WARNING: not yet implemented as a feature at time of writing; this hasn't been double checked.
      const canCreate = useCanRunbookVersion('create')
      return { can: canCreate, error: canCreate ? undefined : RUNBOOK_VERSION_ERRORS.NO_PERMISSION }
    default:
      return handleUnknownKey(action, 'canActionRunbookVersion')
  }
  /* eslint-enable react-hooks/rules-of-hooks */
}

export const useCanActionRunbookVersionCallback: ActiveRunbookVersionModelType['usePermissionCallback'] = action => {
  const runbookVersionValueCb = useGetRunbookVersionCallback()

  return useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        switch (action) {
          case 'convert_to_template':
            const rbv = await runbookVersionValueCb()
            const rb = await snapshot.getPromise(runbookState)
            return canConvertToTemplate(rb.template_type, rbv.run)
          case 'create':
            // TODO: WARNING: not yet implemented as a feature at time of writing; this hasn't been double checked.
            const canCreate = await snapshot.getPromise(runbookVersionPermission({ attribute: 'create' }))
            return { can: canCreate, error: canCreate ? undefined : RUNBOOK_VERSION_ERRORS.NO_PERMISSION }
          case 'runbook_version_import':
            const canImport = await snapshot.getPromise(runbookVersionPermission({ attribute: 'import' }))
            return { can: canImport, error: canImport ? undefined : RUNBOOK_VERSION_ERRORS.NO_PERMISSION }
          default:
            return handleUnknownKey(action, 'canActionRunbookVersionCallback')
        }
      },
    [action, runbookVersionValueCb]
  )
}

// TODO: fix types
// @ts-ignore
export const useOnActionRunbookVersion: ActiveRunbookVersionModelType['useOnAction'] = action => {
  useEnsureStableArgs(action)

  const processCreate = useProcessRunbookVersionCreateResponse()
  const processConvertToTemplate = useProcessRunbookVersionConvertToTemplateResponse()
  const processCsvImport = useProcessRunbookVersionCsvImportResponse()
  const processMarkAsApproved = useProcessRunbookVersionMarkAsApprovedResponse()
  const processRequestApproval = useProcessRunbookVersionRequestApprovalResponse()
  const processCancelApproval = useProcessRunbookVersionCancelApprovalResponse()
  const processSubmitReview = useProcessRunbookVersionSubmitReviewResponse()
  const processMerge = useProcessRunbookVersionMergeResponse()

  switch (action) {
    case 'create':
      return processCreate
    case 'convert_to_template':
      return processConvertToTemplate
    case 'mark_as_approved':
      return processMarkAsApproved
    case 'request_approval':
      return processRequestApproval
    case 'cancel_approval':
      // Note: a cancel 'reapproval' actually deletes a version and reloads the previous one
      return processCancelApproval
    case 'submit_review':
      return processSubmitReview
    case 'runbook_version_import':
      return processCsvImport
    case 'runbook_merged_after_task':
      return processMerge
  }
}

const useProcessRunbookVersionMarkAsApprovedResponse = () => {
  return useRecoilTransaction_UNSTABLE(
    transactionInterface => (response: RunbookVersionMarkAsApprovedResponse) => {
      processVersionApprovalResponse(transactionInterface, response)
    },
    []
  )
}

const useProcessRunbookVersionRequestApprovalResponse = () => {
  return useRecoilTransaction_UNSTABLE(
    transactionInterface => (response: RunbookVersionRequestApprovalResponse) => {
      processVersionApprovalResponse(transactionInterface, response)
    },
    []
  )
}

const useProcessRunbookVersionCancelApprovalResponse = () => {
  const navigateToNewVersion = useNavigateUpdateRunbookVersion()

  const process = useRecoilTransaction_UNSTABLE(
    transactionInterface => (response: RunbookVersionRequestApprovalResponse) => {
      processVersionApprovalResponse(transactionInterface, response)
    }
  )

  return useCallback(
    (response: RunbookVersionRequestApprovalResponse) => {
      process(response)
      const currentVersionId = response.runbook_version.id
      navigateToNewVersion(currentVersionId)
    },
    [navigateToNewVersion, process]
  )
}

const useProcessRunbookVersionSubmitReviewResponse = () => {
  const notify = useNotify()
  const { t } = useLanguage('runbook')

  return useRecoilTransaction_UNSTABLE(
    transactionInterface => (response: RunbookVersionSubmitReviewResponse) => {
      processVersionApprovalResponse(transactionInterface, response)
      notify.success(t('submitReviewModal.successText'))
    },
    [notify, t]
  )
}

const useProcessRunbookVersionCreateResponse = () => {
  const navigateToNewVersion = useNavigateUpdateRunbookVersion()

  const process = useRecoilTransaction_UNSTABLE(
    transactionInterface => (response: RunbookVersionCreateResponse) => {
      const { set } = transactionInterface

      updateCurrentVersion(transactionInterface)(response.runbook_version)

      // Note: what is the point of this when the whole thing is immediately reloaded
      set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
        produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
          extend(draftRunbookVersionResponse.runbook_version, response.runbook_version)
        })
      )

      set(runbookResponseState_INTERNAL, prevRunbookResponse =>
        produce(prevRunbookResponse, draftRunbookResponse => {
          draftRunbookResponse.meta.permissions.update = response.meta.permissions.runbook.update
        })
      )
    },
    []
  )

  return useCallback(
    (response: RunbookVersionCreateResponse) => {
      process(response)
      const currentVersionId = response.runbook_version.id
      navigateToNewVersion(currentVersionId)
    },
    [navigateToNewVersion, process]
  )
}

const useProcessRunbookVersionConvertToTemplateResponse = () => {
  return useRecoilTransaction_UNSTABLE(
    transactionInterface => (response: RunbookVersionConvertToTemplateResponse) => {
      const { set } = transactionInterface

      set(runbookResponseState_INTERNAL, prevRunbookResponse =>
        produce(prevRunbookResponse, draftRunbookResponse => {
          draftRunbookResponse.runbook.is_template = response.meta.runbook_is_template
          draftRunbookResponse.runbook.template_status = response.meta.runbook_template_status
          if (response.meta.runbook_is_template) draftRunbookResponse.runbook.template_type = 'default'
        })
      )

      set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
        produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
          extend(draftRunbookVersionResponse.runbook_version, response.runbook_version)
          extend(draftRunbookVersionResponse.meta.permissions, response.meta.permissions)
        })
      )
    },
    []
  )
}

const useProcessRunbookVersionCsvImportResponse = () => {
  const refetchRunbookVersion = useReloadRunbookVersion()
  // TODO: replace with new task hook when completed
  const refetchTasks = useReloadTasks()

  // FIXME: seems wrong. we don't update the runbook state's current_version and runbook_version_id
  return useRecoilCallback(
    ({ set }) =>
      async (data: RunbookVersionImportResponse) => {
        set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
          produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
            extend(draftRunbookVersionResponse.runbook_version, data.runbook_version)
          })
        )

        await refetchRunbookVersion()
        await refetchTasks()
      },
    [refetchTasks, refetchRunbookVersion]
  )
}

const processVersionApprovalResponse = (
  { set }: TransactionInterface_UNSTABLE,
  response:
    | RunbookVersionMarkAsApprovedResponse
    | RunbookVersionRequestApprovalResponse
    | RunbookVersionSubmitReviewResponse
) => {
  set(runbookResponseState_INTERNAL, prevRunbookResponse =>
    produce(prevRunbookResponse, draftRunbookResponse => {
      draftRunbookResponse.runbook.template_status = response.meta.runbook_template_status
      draftRunbookResponse.meta.permissions.update = response.meta.permissions.runbook.update
    })
  )

  set(runbookVersionResponseState_INTERNAL, prevRunbookVersionResponse =>
    produce(prevRunbookVersionResponse, draftRunbookVersionResponse => {
      extend(draftRunbookVersionResponse.runbook_version, response.runbook_version)
      extend(draftRunbookVersionResponse.meta.permissions, response.meta.permissions)
    })
  )
}

const useProcessRunbookVersionMergeResponse = () => {
  const { t } = useLanguage('runbook')
  const notify = useNotify()

  const updatePageData = useRecoilTransaction_UNSTABLE(
    transactionInterface =>
      ({
        taskData,
        runbookVersionData,
        response
      }: {
        taskData: TaskListResponseType
        runbookVersionData: GetRunbookVersionResponse
        response: RunbookVersionMergedResponse
      }) => {
        const { set, get } = transactionInterface
        const currentTaskTotal = get(taskListResponseState_INTERNAL).tasks.length

        set(
          taskListResponseState_INTERNAL,
          taskListResponseDataHelpers.extend(taskData, {
            runbookComponents: runbookVersionData.meta.runbook_components
          })
        )

        set(
          runbookVersionResponseState_INTERNAL,
          produce(runbookVersionData, draft => {
            extend(draft, runbookVersionData)
          })
        )

        notify.success(
          t('validateSnippetModal.notification.success.notification', {
            userName: response.meta.headers.request_user_name,
            numberOfTasks: taskData.tasks.length - currentTaskTotal
          })
        )
      },
    []
  )

  return useRecoilCallback(
    () => async (response: RunbookVersionMergedResponse) => {
      // Runbook version is required for all new teams
      const [taskData, runbookVersionData] = await Promise.all([
        getTasks(response.runbook_version.runbook_id, response.runbook_version.id),
        getRunbookVersion(response.runbook_version.runbook_id, response.runbook_version.id)
      ])

      updatePageData({
        taskData,
        runbookVersionData,
        response
      })
    },
    [updatePageData]
  )
}
